import React from 'react';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

import { round } from './duty';

interface ResultItemProps {
  numOfCigars?: number;
  className?: string;
  label: string;
  value: number;
}

const ResultItem: React.FC<ResultItemProps> = ({
  className,
  label,
  numOfCigars,
  value,
}) => {
  const result = round(value).toFixed(2);

  return (
    <>
      <TableCell className={className}>{label}</TableCell>
      <TableCell className={className}>
        ${result}
        {numOfCigars && (
          <Typography variant="caption" display="block" sx={{ mt: 1 }}>
            ${round(value / numOfCigars).toFixed(2)} per cigar
          </Typography>
        )}
      </TableCell>
    </>
  );
};

export default ResultItem;
