import React from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { getTaxes, userInput } from './duty';
import Results from './Results';

import './App.css';

function App() {
  const [orderTotal, setOrderTotal] = React.useState<string | undefined>('');
  const [numOfCigars, setNumOfCigars] = React.useState<string | undefined>('');
  const [province, setProvince] = React.useState('');
  const [currency, setCurrency] = React.useState('');
  const [taxes, setTaxes] = React.useState() as any;
  const [userInput, setUserInput] = React.useState() as any;
  const [inFlight, setInFlight] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(false);
    setInFlight(true);
    const data = new FormData(e.currentTarget);
    const inputs: userInput = {
      currency: data.get('currency') as string,
      numOfCigars: Number(data.get('numOfCigars')),
      orderTotal: Number(data.get('orderTotal')),
      province: data.get('province') as string,
    };

    // @ts-ignore
    /*
    window.plausible('DutyFormSubmit', {
      props: { ...inputs, aggregate: JSON.stringify(inputs) },
    });
    */

    if (inputs.currency !== 'CAD') {
      fetch(`/currency.php?currency=${inputs.currency}`)
        .then((response) => response.json())
        .then((data) => {
          inputs.rate = data.rates.CAD;
          inputs.orderTotal = inputs.orderTotal * data.rates.CAD;
          setUserInput(inputs);
          setTaxes(getTaxes(inputs));
          setInFlight(false);
          setError(false);
        })
        .catch(() => {
          // @ts-ignore
          window.plausible('ErrorCurrency', {
            props: {
              aggregate: JSON.stringify(inputs),
              currency: inputs.currency,
            },
          });
          setError(true);
          setInFlight(false);
        });
    } else {
      setUserInput(inputs);
      setTaxes(getTaxes(inputs));
      setInFlight(false);
      setError(false);
    }
  };

  const handleOrderTotal = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderTotal(e.target.value as unknown as string);
  };

  const handleNumOfCigars = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNumOfCigars(e.target.value as unknown as string);
  };

  const selectProvince = (e: SelectChangeEvent) => {
    setProvince(e.target.value as string);
  };

  const selectCurrency = (e: SelectChangeEvent) => {
    setCurrency(e.target.value as string);
  };

  const currencySymbol = {
    USD: '$',
    CAD: '$',
    EUR: '€',
    GBP: '£',
  };

  const getCurrencySymbol = (currency: string) =>
    // @ts-ignore
    currencySymbol[currency] || '$';

  const startOver = () => {
    // @ts-ignore
    /*
    window.plausible('StartOver');
    setTaxes();
    */
    setTaxes();
  };

  return (
    <div className='App'>
      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justifyContent='center'
        style={{ minHeight: '100vh' }}
      >
        <Container component='main' maxWidth='sm' sx={{ py: 3 }}>
          <CssBaseline />
          <Typography
            variant='h5'
            display='block'
            component='h1'
            sx={{ mb: 2 }}
          >
            Cigar Duties Canada 🇨🇦
          </Typography>
          {!taxes && (
            <>
              <p>Calculate taxes & duties when importing cigars into Canada.</p>
              <Box component='form' onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <FormControl fullWidth margin='normal'>
                  <InputLabel id='province' required>
                    Province
                  </InputLabel>
                  <Select
                    id='province'
                    label='Province'
                    name='province'
                    value={province}
                    onChange={selectProvince}
                    required
                  >
                    <MenuItem value='ab'>Alberta</MenuItem>
                    <MenuItem value='bc'>British Columbia</MenuItem>
                    <MenuItem value='mb'>Manitoba</MenuItem>
                    <MenuItem value='nb'>New Brunswick</MenuItem>
                    <MenuItem value='nl'>Newfoundland and Labrador</MenuItem>
                    <MenuItem value='nwt'>Northwest Territories</MenuItem>
                    <MenuItem value='ns'>Nova Scotia</MenuItem>
                    <MenuItem value='nu'>Nunavut</MenuItem>
                    <MenuItem value='on'>Ontario</MenuItem>
                    <MenuItem value='pei'>Prince Edward Island</MenuItem>
                    <MenuItem value='qc'>Quebec</MenuItem>
                    <MenuItem value='sk'>Saskatchewan</MenuItem>
                    <MenuItem value='yu'>Yukon</MenuItem>
                  </Select>
                </FormControl>
                <Grid container spacing={1}>
                  <Grid item xs={7}>
                    <FormControl margin='normal' fullWidth>
                      <TextField
                        fullWidth
                        id='orderTotal'
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              {getCurrencySymbol(currency)}
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          step: '0.01',
                        }}
                        label='Order value excluding shipping'
                        name='orderTotal'
                        type='number'
                        value={orderTotal}
                        onChange={handleOrderTotal}
                        required
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    <FormControl margin='normal' fullWidth>
                      <InputLabel id='currency' required>
                        Order currency
                      </InputLabel>
                      <Select
                        id='currency'
                        label='Order currency'
                        name='currency'
                        value={currency}
                        onChange={selectCurrency}
                        required
                      >
                        <MenuItem value='CAD'>CAD</MenuItem>
                        <MenuItem value='USD'>USD</MenuItem>
                        <MenuItem value='EUR'>EUR</MenuItem>
                        <MenuItem value='GBP'>GBP</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <FormControl fullWidth margin='normal'>
                  <TextField
                    fullWidth
                    id='numOfCigars'
                    label='Number of cigars'
                    name='numOfCigars'
                    type='number'
                    value={numOfCigars}
                    onChange={handleNumOfCigars}
                    required
                  />
                </FormControl>
                <LoadingButton
                  loading={inFlight}
                  type='submit'
                  variant='contained'
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  Submit
                </LoadingButton>
                {error && (
                  <Alert severity='error' sx={{ mt: 2 }}>
                    Something went wrong with the currency conversion. Please
                    try using CAD.
                  </Alert>
                )}
              </Box>
            </>
          )}
          {taxes && (
            <Results
              startOver={startOver}
              taxes={taxes}
              userInput={userInput}
            />
          )}
        </Container>
      </Grid>
    </div>
  );
}

export default App;
