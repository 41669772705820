export interface userInput {
  currency: string;
  numOfCigars: number;
  orderTotal: number;
  province: string;
  rate?: number;
}

interface dutyValueProp {
  maxTax?: number;
  exciseTax: number;
  salesTax: number;
}

interface dutyValuesProp {
  [key: string]: dutyValueProp;
}

interface salesTax {
  federal: number;
  provincial: number;
}

const dutyValues: dutyValuesProp = {
  // https://www.alberta.ca/about-tax-levy-rates-prescribed-interest-rates.aspx#tobacco
  ab: {
    exciseTax: 1.42,
    maxTax: 8.61,
    salesTax: 0.05,
  },
  // https://www2.gov.bc.ca/gov/content/taxes/sales-taxes/tobacco-tax
  bc: {
    exciseTax: 0.905,
    maxTax: 7,
    salesTax: 0.12,
  },
  // https://www.gov.mb.ca/finance/taxation/taxes/tobacco.html
  mb: {
    exciseTax: 0.75,
    maxTax: 5,
    salesTax: 0.12,
  },
  // https://www2.gnb.ca/content/gnb/en/departments/finance/taxes/retailers.html
  nb: {
    exciseTax: 0.75,
    salesTax: 0.12,
  },
  // https://www.gov.nl.ca/fin/tax-programs-incentives/personal/tobacco/#:~:text=General%20Taxation,Cigars%20%E2%80%93%2056%20cents%20per%20gram
  nl: {
    exciseTax: 1.25,
    salesTax: 0.15,
  },
  // https://beta.novascotia.ca/documents/tobacco-tax-program-overview
  ns: {
    exciseTax: 0.75,
    salesTax: 0.15,
  },
  // https://www.ontario.ca/document/tobacco-tax#:~:text=Tobacco%20tax%20rates,-As%20of%2012&text=18.475%C2%A2%20per%20cigarette,other%20than%20cigarettes%20and%20cigars
  on: {
    exciseTax: 0.566,
    salesTax: 0.13,
  },
  // https://www.princeedwardisland.ca/en/information/finance/tobacco-tax
  pei: {
    exciseTax: 0.75,
    salesTax: 0.15,
  },
  // https://www.revenuquebec.ca/en/online-services/forms-and-publications/current-details/ta-1-v/
  qc: {
    exciseTax: 0.8,
    salesTax: 0.15,
  },
  // https://www.saskatchewan.ca/business/taxes-licensing-and-reporting/provincial-taxes-policies-and-bulletins/tobacco-tax
  sk: {
    exciseTax: 1,
    maxTax: 5,
    salesTax: 0.11,
  },
  // https://www.fin.gov.nt.ca/en/services/licences-taxes-and-fees/tobacco-tax
  nwt: {
    exciseTax: 0.75,
    salesTax: 0.05,
  },
  // https://www.gov.nu.ca/finance/information/tobacco-taxes
  nu: {
    exciseTax: 1.4,
    salesTax: 0.05,
  },
  // https://yukon.ca/en/tobacco-tax-rates
  yu: {
    exciseTax: 1.3,
    salesTax: 0.05,
  },
};

export const round = (value: number) =>
  Math.round((value + Number.EPSILON) * 100) / 100;

export const getProvincialTax = (userInput: userInput): number => {
  const duty = dutyValues[userInput.province];
  const federal = getFederalTax(userInput);
  const pricePerCigar =
    (userInput.orderTotal + federal.duty + federal.excise) /
    userInput.numOfCigars;
  const taxPerCigar = pricePerCigar * duty.exciseTax;
  let taxes = taxPerCigar * userInput.numOfCigars;

  if (duty.maxTax && duty.maxTax < taxPerCigar) {
    taxes = duty.maxTax * userInput.numOfCigars;
  }

  return taxes;
};

export const getFederalTax = (userInput: userInput) => {
  const duty = (32.41649 / 1000) * userInput.numOfCigars;
  const excise = (userInput.orderTotal + duty) * 0.88;
  return {
    duty: duty,
    excise: excise,
  };
};

const getSalesTax = (userInput: userInput): salesTax => {
  const duty = dutyValues[userInput.province];
  const federal = getFederalTax(userInput);
  const federalTotal = federal.excise + federal.duty;
  return {
    federal: 0.05 * federalTotal,
    provincial: duty.salesTax * federalTotal,
  };
};

export const getTaxes = (userInput: userInput) => ({
  federal: getFederalTax(userInput),
  handlingFee: 9.95,
  provincial: getProvincialTax(userInput),
  salesTax: getSalesTax(userInput),
});
