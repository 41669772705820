import React from "react";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import ResultItem from "./ResultItem";

import { userInput } from "./duty";
import "./Results.css";

interface ResultsProps {
  startOver: any;
  taxes: any;
  userInput: userInput;
}

const Results: React.FC<ResultsProps> = ({ startOver, taxes, userInput }) => {
  const [excludePTT, setExcludePTT] = React.useState(false);

  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExcludePTT(event.target.checked);

    /*
    if (event.target.checked) {
      // @ts-ignore
      window.plausible('ExcludePTT');
    }
    */
  };

  const totalTaxes = !excludePTT
    ? taxes.federal.duty +
      taxes.federal.excise +
      taxes.salesTax.provincial +
      taxes.provincial +
      taxes.handlingFee
    : taxes.federal.duty + taxes.federal.excise + taxes.salesTax.federal;

  const grandTotal = totalTaxes + userInput.orderTotal;

  const salesTax = !excludePTT
    ? taxes.salesTax.provincial
    : taxes.salesTax.federal;

  return (
    <>
      <Stack spacing={2}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <ResultItem label="Duty" value={taxes.federal.duty} />
              </TableRow>
              <TableRow>
                <ResultItem label="Excise Tax" value={taxes.federal.excise} />
              </TableRow>
              <TableRow>
                <ResultItem label="Sales Tax" value={salesTax} />
              </TableRow>
              {!excludePTT && (
                <TableRow>
                  <ResultItem
                    label={`Provincial Tax (${userInput.province.toUpperCase()})`}
                    value={taxes.provincial}
                  />
                </TableRow>
              )}
              {!excludePTT && (
                <TableRow>
                  <ResultItem
                    label="CBSA Handling Fee"
                    value={taxes.handlingFee}
                  />
                </TableRow>
              )}
              <TableRow>
                <ResultItem
                  className="Results__item--strong"
                  label="Total Taxes"
                  numOfCigars={userInput.numOfCigars}
                  value={totalTaxes}
                />
              </TableRow>
              <TableRow>
                <ResultItem
                  label={`Order Value (${userInput.numOfCigars} Cigar${
                    userInput.numOfCigars === 1 ? "" : "s"
                  })`}
                  value={userInput.orderTotal}
                />
              </TableRow>
              <TableRow>
                <ResultItem
                  className="Results__item--strong"
                  label="Grand Total"
                  numOfCigars={userInput.numOfCigars}
                  value={grandTotal}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <FormGroup
          sx={{
            alignItems: "center",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox checked={excludePTT} onChange={handleCheckbox} />
            }
            label="Exclude provincial tobacco tax and CBSA fee."
          />
          <FormHelperText sx={{ mb: 1, mt: 0 }}>
            Useful if you are entering Canada via a province other than your
            home province. <br /> Example: Landing in Ottawa but you live in
            Montreal.
          </FormHelperText>
        </FormGroup>
        <Button onClick={startOver} variant="contained" color="success">
          Start over
        </Button>
      </Stack>
      <Typography variant="caption" display="block" sx={{ mt: 3 }}>
        All values are estimates based on tax and duty rates as of July 2023.
      </Typography>
      <Typography variant="caption" display="block">
        All values are in CAD.
        {userInput.currency !== "CAD" && (
          <>
            {" "}
            Conversion rate 1 {userInput.currency} to {userInput.rate} CAD.
          </>
        )}
      </Typography>
    </>
  );
};

export default Results;
